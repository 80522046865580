const textStyles = {
  h1: {
    // you can also use responsive styles
    fontSize: "60px",
    fontWeight: "800",
    lineHeight: "1.2",
    letterSpacing: "0",
    marginBottom: "24px",
  },
  h2: {
    // you can also use responsive styles
    // fontSize: "50px",
    fontSize: ["28px", "28px", "calc(40px + 0.390625vw)"],
    fontWeight: "700",
    lineHeight: ["35px", "35px", "52px"],
    letterSpacing: "0",
    // marginBottom: "24px",
  },
  h3: {
    fontSize: "25px",
    fontWeight: "700",
    lineHeight: "1.2",
    letterSpacing: "0",
    maxWidth: "800px",
    margin: "auto",
    marginBottom: "24px",
  },

  h4: {
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "30px",
    letterSpacing: "0",
    maxWidth: "800px",
    margin: "auto",
    marginBottom: "24px",
  },
  h5: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "30px",
    letterSpacing: "0",
    // maxWidth: "800px",
    margin: "auto",
    marginBottom: "24px",
  },
  p: {
    // fontSize: "18px",
    fontSize: "16px",
    fontWeight: "400",
    // lineHeight: "30px",
    lineHeight: "28px",
    letterSpacing: "0",
    // maxWidth: "800px",
    margin: "auto",
    marginBottom: "24px",
    // color: "brand.grey",
  },
  footer: {
    // fontSize: "18px",
    fontSize: "14px",
    fontWeight: "400",
    // lineHeight: "30px",
    lineHeight: "28px",
    letterSpacing: "0",
    // maxWidth: "800px",
    margin: "auto",

    // marginBottom: "24px",
    // color: "brand.grey",
  },
};

export default textStyles;
