const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
    black: "#141414",
    white: "#FFFFFF",
    grey: "#6a6b6c",
    lightGrey: "#f3f3f3",
  },
  grey: {
    100: "#cdc5c5",
  },
};

export default colors;
