import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styles from "./ModalForm.module.css";
import { Box, Flex, Heading, Text, InputGroup, InputLeftElement, Textarea } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import { PhoneIcon, AddIcon, WarningIcon, EmailIcon } from "@chakra-ui/icons";

interface ContactFormValues {
  name: string;
  email: string;
  phone: string;
}

interface IModalFormProps {
  modalCtaText: string;
  modalTitle: string;
  successMessage: string;
}

const ModalForm = ({ modalCtaText, modalTitle, successMessage }: IModalFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const initialValues: ContactFormValues = {
    name: "",
    email: "",
    phone: "",
  };

  const validationSchema: Yup.ObjectSchema<ContactFormValues> = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
  });

  const handleSubmit = async (values: ContactFormValues, { resetForm }: any) => {
    setIsSubmitting(true);

    try {
      const response = await fetch("/api/arrangeACall", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        setSubmitSuccess(true);
        resetForm();
      } else {
        const errorData = await response.json();
        setSubmitError(errorData.message);
      }
    } catch (error) {
      console.error("Error sending form data:", error);
      setSubmitError("An error occurred while submitting the form.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Box className={styles.contactWrapper}>
        <Box className="container">
          {submitSuccess ? (
            <>
              <Heading textStyle="h2" as="h2" textAlign="center" fontSize="40px" marginBottom="34px" letterSpacing="1px" color="brand.dark">
                Thank You!
              </Heading>
              <Box marginTop="24px">
                <Text textStyle="p" as="p" color="inherit" textAlign={"center"}>
                  {successMessage}
                </Text>
              </Box>
            </>
          ) : (
            <>
              <Heading textStyle="h2" as="h2" fontSize="23px" letterSpacing="1px" color="brand.dark" marginBottom="20px" textTransform="capitalize">
                {modalTitle || "Modal Title"}
              </Heading>
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                <Form>
                  <Box className={styles.fieldWrapper}>
                    {/* <label htmlFor="name">Name</label> */}
                    <Field name="name">{({ field }) => <Input {...field} type="text" placeholder="Name" />}</Field>

                    <ErrorMessage name="name" component="div" className="error" />
                  </Box>
                  <Box className={styles.fieldWrapper}>
                    {/* <label htmlFor="email">Email</label> */}
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <EmailIcon color="gray.600" />
                      </InputLeftElement>
                      <Field name="email">{({ field }) => <Input {...field} type="text" placeholder="Email" />}</Field>
                    </InputGroup>
                    <ErrorMessage name="email" component="div" className="error" />
                  </Box>
                  <Box className={styles.fieldWrapper}>
                    {/* <label htmlFor="phone">Phone</label> */}
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <PhoneIcon color="gray.600" />
                      </InputLeftElement>
                      <Field name="phone">{({ field }) => <Input {...field} type="tel" placeholder="Phone" />}</Field>
                    </InputGroup>
                    <ErrorMessage name="phone" component="div" className="error" />
                  </Box>
                  {/* <Box className={styles.fieldWrapper}>
                    <Field name="message">{({ field }) => <Textarea {...field} type="textarea" placeholder="Message" />}</Field>
                    <ErrorMessage name="message" component="div" className="error" />
                  </Box> */}
                  <button className={["button button--dark", styles.modalForm__button].join(" ")} type="submit" disabled={isSubmitting}>
                    {modalCtaText}
                  </button>
                  {submitError && <p className="error">Error: {submitError}</p>}
                </Form>
              </Formik>
            </>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default ModalForm;
