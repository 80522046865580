import React, { createContext, useContext, useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import "@/styles/globals.css";
import theme from "@/styles/theme";
import { PrismicPreview } from "@prismicio/next";
import { repositoryName } from "@/prismicio";
import { Button, useDisclosure } from "@chakra-ui/react";
import CustomModal from "@/components/CustomModal/CustomModal";
import { ModalContextProvider } from "@/contexts/ModalContext";
import DownloadModal from "@/components/CustomModal/DownloadModal";
import BackToTopButton from "@/components/BackToTop/BackToTopButton";
import CookieConsent from "@/components/CookiesConsent/CookiesConsent";
import { CookieConsentProvider } from "@/contexts/CoookieConsentContext";

export default function App({ Component, pageProps }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <CookieConsentProvider>
      <ModalContextProvider>
        <ChakraProvider theme={theme}>
          <CustomModal isOpen={isOpen} onClose={onClose} />
          <DownloadModal isOpen={isOpen} onClose={onClose} />

          <Component {...pageProps} />

          {process.env.NODE_ENV === "development" && (
            <PrismicPreview repositoryName={repositoryName} />
          )}

          <CookieConsent />
          <BackToTopButton />
        </ChakraProvider>
      </ModalContextProvider>
    </CookieConsentProvider>
  );
}
