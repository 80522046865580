import { createContext, useState } from "react";

const ModalContext = createContext({
  isModalOpen: false,
  isDownloadModalOpen: false,
  openModal: () => {},
  openDownloadModal: () => {},
  closeModal: () => {},
  closeDownloadModal: () => {},
});

const ModalContextProvider = ({ children }) => {
  const [contextValue, setContextValue] = useState({
    isModalOpen: false,
    isDownloadModalOpen: false,
  });

  // Open the modal
  const openModal = () => {
    setContextValue((prevState) => ({ ...prevState, isModalOpen: true }));
  };

  // Open the download modal
  const openDownloadModal = () => {
    setContextValue((prevState) => ({
      ...prevState,
      isDownloadModalOpen: true,
    }));
  };

  // Close the modal
  const closeModal = () => {
    setContextValue((prevState) => ({ ...prevState, isModalOpen: false }));
  };

  // Close the download modal
  const closeDownloadModal = () => {
    setContextValue((prevState) => ({
      ...prevState,
      isModalOpen: false,
      isDownloadModalOpen: false,
    }));
  };

  return (
    <ModalContext.Provider
      value={{
        ...contextValue,
        openModal,
        closeModal,
        openDownloadModal,
        closeDownloadModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalContextProvider };
