// components/CookieConsent.js
import { useState, useEffect } from "react";
import Link from "next/link"; // Ensure Link is imported
import { useCookieConsent } from "@/contexts/CoookieConsentContext";

export default function CookieConsent() {
  const { consentStatus, setConsentStatus } = useCookieConsent();

  if (consentStatus !== null) {
    return null; // If a decision has been made (either 'given' or 'declined'), do not show the bar
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-900 text-white p-4 text-center">
      <p className="mb-2">
        🍪 We use cookies to enhance your experience. By continuing to visit
        this site you agree to our use of{" "}
        <Link href="/cookie-policy" className="underline">
          cookies
        </Link>
        .
      </p>
      <button
        onClick={() => setConsentStatus("given")}
        className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
      >
        Accept All
      </button>
      <button
        onClick={() => setConsentStatus("declined")}
        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
      >
        Decline Non-Essential
      </button>
    </div>
  );
}
