// CustomModal.tsx
import React, { useContext } from "react";
import * as prismic from "@prismicio/client";

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Box, Flex, Input, Heading } from "@chakra-ui/react";
import styles from "./CustomModal.module.css";
import { FormControl, FormLabel, FormErrorMessage, FormHelperText, Spinner } from "@chakra-ui/react";
import { ModalContext } from "@/contexts/ModalContext";
import useSettings from "@/hooks/useSettings";
import ModalForm from "../ModalForm/ModalForm";

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({ isOpen, onClose }) => {
  const { settings, isLoading, error } = useSettings();

  const backgroundImage = settings?.modal_image?.url;
  const modalTitle = prismic.asText(settings?.modal_title);
  const modalCtaText = prismic.asText(settings?.modal_cta_text);
  const successMessage = prismic.asText(settings?.contact_form_success_message);
  const fallbackImage = "http://via.placeholder.com/600x600";

  const divStyle = {
    backgroundImage: `url(${backgroundImage ? backgroundImage : fallbackImage})`,
  };

  const { isModalOpen, closeModal } = useContext(ModalContext);

  const handleModalClose = () => {
    closeModal();
  };

  return (
    <Modal isOpen={isModalOpen} onClose={handleModalClose} isCentered motionPreset="slideInBottom">
      <ModalOverlay background="rgb(0 0 0 / 68%)" />
      <ModalContent className={styles.modal}>
        {/* <ModalHeader className={styles.modalHeader}>Modal Title</ModalHeader> */}

        {isLoading || error ? (
          <Flex alignItems="center" justifyContent="center" height="400px">
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          </Flex>
        ) : (
          <>
            <ModalCloseButton className={styles.modal__closeButton} />
            <ModalBody className={styles.modalBody}>
              <Flex className={styles.modal__body}>
                <Box className={styles.modalLeft} style={divStyle}></Box>
                <Box className={styles.modalRight}>
                  <ModalForm successMessage={successMessage || successMessage} modalTitle={modalTitle || "Modal Title"} modalCtaText={modalCtaText || "CTA Text"} />
                </Box>
              </Flex>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
