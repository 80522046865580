import { useEffect, useState } from "react";
import { createClient } from "@/prismicio";
import { SettingsDocumentData } from "../../prismicio-types";

/**
 * Custom hook for fetching site settings.
 */
const UseSettings = (): {
  settings: SettingsDocumentData | null;
  isLoading: boolean;
  error: Error | null;
} => {
  const [settings, setSettings] = useState<SettingsDocumentData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const getSettingsData = async () => {
      try {
        const client = createClient();
        // const settingsData = await client.getSingle("settings"); // Rebuild
        const settingsData = await client.getSingle("settings");

        setSettings(settingsData.data);
        setIsLoading(false);
        // console.log("settings within hook are", settingsData);
      } catch (error) {
        // Set the error state if an error occurs
        setError(error);
        setIsLoading(false);
      }
    };

    getSettingsData();
  }, []);

  return { settings, isLoading, error };
};

export default UseSettings;
