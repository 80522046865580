// context/CookieConsentContext.tsx
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";

// Define the context state interface
interface CookieConsentContextState {
  consentStatus: "given" | "declined" | null; // This now explicitly includes all three possibilities
  setConsentStatus: (status: "given" | "declined") => void;
}

// Create the context with a default state of null indicating no decision made
const CookieConsentContext = createContext<
  CookieConsentContextState | undefined
>(undefined);

interface CookieConsentProviderProps {
  children: ReactNode;
}

export const CookieConsentProvider: React.FC<CookieConsentProviderProps> = ({
  children,
}) => {
  const [consentStatus, setConsentStatus] = useState<
    "given" | "declined" | null
  >(null);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent === "given" || consent === "declined") {
      setConsentStatus(consent);
    }
  }, []);

  const handleSetConsentStatus = (status: "given" | "declined") => {
    localStorage.setItem("cookieConsent", status);
    setConsentStatus(status);
  };

  return (
    <CookieConsentContext.Provider
      value={{ consentStatus, setConsentStatus: handleSetConsentStatus }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};

// Custom hook to use the context
export const useCookieConsent = (): CookieConsentContextState => {
  const context = useContext(CookieConsentContext);
  if (context === undefined) {
    throw new Error(
      "useCookieConsent must be used within a CookieConsentProvider"
    );
  }
  return context;
};
