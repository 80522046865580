import { extendTheme } from "@chakra-ui/react";
import colors from "./colors";
import fonts from "./fonts";
import textStyles from "./textStyles";

const theme = extendTheme({
  fonts,
  colors,
  //   textStyles: {
  //     h1: {
  //       // you can also use responsive styles
  //       fontSize: "80px",
  //       fontWeight: "800",
  //       lineHeight: "110%",
  //       letterSpacing: "-2%",
  //     },
  //   },
  textStyles,
  //   layerStyles: {
  //     base: {
  //       bg: "gray.50",
  //       border: "2px solid",
  //       borderColor: "gray.500",
  //     },
  //     selected: {
  //       bg: "teal.500",
  //       color: "teal.700",
  //       borderColor: "orange.500",
  //     },
  //   },
});

export default theme;
