import { Averia_Serif_Libre, Montserrat, Ubuntu } from "next/font/google";

const nextFont = Montserrat({
  weight: ["400", "600", "800", "900"],
  subsets: ["latin"],
});

const ubuntuFont = Ubuntu({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
});

const fonts = {
  body: ubuntuFont.style.fontFamily,
  heading: nextFont.style.fontFamily,
};

export default fonts;
