import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styles from "./ModalForm.module.css";
import {
  Box,
  Flex,
  Heading,
  Text,
  InputGroup,
  InputLeftElement,
  Textarea,
} from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import { PhoneIcon, AddIcon, WarningIcon, EmailIcon } from "@chakra-ui/icons";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef } from "react";

interface FormValues {
  name: string;
  email: string;
}

interface IModalFormProps {}

const DownloadModalForm = ({}: IModalFormProps) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const initialValues: FormValues = {
    name: "",
    email: "",
  };

  const validationSchema: Yup.ObjectSchema<FormValues> = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleSubmit = async (values: FormValues, { resetForm }: any) => {
    setIsSubmitting(true);
    setSubmitSuccess(true);
    //     resetForm();

    try {
      const token = await recaptchaRef.current.executeAsync();
      if (!token) {
        throw new Error("ReCAPTCHA failed");
      }

      const payload = {
        ...values,
        recaptcha: token,
      };

      const response = await fetch("/api/downloadProspectus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        const data = await response.json();
      } else {
        throw new Error("Failed to send data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div className="reCaptchaContainer">
        <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef as any}
          sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY || ""}
        />
      </div>
      <Box className={styles.contactWrapper}>
        <Box className="container" padding="70px 45px">
          {submitSuccess ? (
            <>
              <Heading
                textStyle="h2"
                as="h2"
                textAlign="center"
                fontSize="40px"
                marginBottom="34px"
                letterSpacing="1px"
                color="brand.dark"
              >
                Thank You!
              </Heading>
              <Box marginTop="24px">
                <Text textStyle="p" as="p" color="inherit" textAlign={"center"}>
                  Please check your inbox for the Course Prospectus.
                </Text>
              </Box>
            </>
          ) : (
            <>
              <Heading
                textStyle="h2"
                as="h2"
                fontSize="23px"
                letterSpacing="1px"
                color="brand.dark"
                marginBottom="20px"
                textTransform="capitalize"
              >
                Enter Your Details Below To Download The Course Prospectus
              </Heading>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  <Box className={styles.fieldWrapper}>
                    {/* <label htmlFor="name">Name</label> */}
                    <Field name="name">
                      {({ field }) => (
                        <Input {...field} type="text" placeholder="Name" />
                      )}
                    </Field>

                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error"
                    />
                  </Box>
                  <Box className={styles.fieldWrapper}>
                    {/* <label htmlFor="email">Email</label> */}
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <EmailIcon color="gray.600" />
                      </InputLeftElement>
                      <Field name="email">
                        {({ field }) => (
                          <Input {...field} type="text" placeholder="Email" />
                        )}
                      </Field>
                    </InputGroup>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </Box>

                  <button
                    className={[
                      "button button--dark",
                      styles.modalForm__button,
                    ].join(" ")}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Download Now
                  </button>
                  {submitError && <p className="error">Error: {submitError}</p>}
                </Form>
              </Formik>
            </>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default DownloadModalForm;
